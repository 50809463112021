import React from 'react';
import Layout from '../layout';
import Image from '../components/Image';
import './indexPage.scss';

class IndexPage extends React.PureComponent<{}, {}> {
  public render() {
    return (
      <Layout>
        <h1>Bienvenue! Welcome!</h1>
        <p>Cette page est en construction.</p>
        <p>This page is under construction.</p>
        <figure className="indexPage__imgContainer">
          <Image />
        </figure>

        <p>Copyright © 2010-2020 by Mimma Nicosia. All rights reserved.</p>
      </Layout>
    );
  }
}

export default IndexPage;
