import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img, { GatsbyImageProps } from 'gatsby-image';

interface StaticQueryProps {
  placeholderImage: {
    childImageSharp: {
      fluid: GatsbyImageProps['fluid'];
    };
  };
}

class Image extends React.PureComponent<{}, {}> {
  public render() {
    const graphqlQuery = graphql`
      query {
        placeholderImage: file(relativePath: { eq: "chicken_bag.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `;

    return (
      <StaticQuery
        query={graphqlQuery}
        render={(data: StaticQueryProps) => (
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            alt="Gatsby + TypeScript"
          />
        )}
      />
    );
  }
}

export default Image;
